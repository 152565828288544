import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'money',
  standalone: true,
})
export class MoneyPipe implements PipeTransform {
   formatter: Intl.NumberFormat

  constructor() {
     this.formatter = new Intl.NumberFormat("de-CH" , {
       // this would add CHF in front for all
       // style: "currency",
       // currency: "CHF",
       // currencySign: "standard",
       minimumIntegerDigits: 1,
       minimumFractionDigits: 2,
       maximumFractionDigits: 2
     })
  }

  transform(value: number|bigint): string {
    return this.formatter.format(value)
  }

}
